import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { auth, initializationPromise } from './firebaseConfig';

// Create router with future flags enabled
const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  }
], {
  basename: process.env.PUBLIC_URL,
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
});

// Wait for Firebase Auth to initialize before rendering
const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </React.StrictMode>
  );
};

// Check if auth is initialized
const waitForAuth = async () => {
  try {
    await initializationPromise;
    return true;
  } catch (error) {
    console.error('Error initializing auth:', error);
    return false;
  }
};

// Initialize the app
waitForAuth()
  .then((initialized) => {
    if (initialized) {
      renderApp();
    } else {
      console.error('Failed to initialize auth');
    }
  });

import { auth, initializationPromise } from '../firebaseConfig';
import { 
  setPersistence, 
  browserLocalPersistence,
  signOut
} from 'firebase/auth';

let inactivityTimeout;
const TIMEOUT_DURATION = 20 * 60 * 1000; // 20 minutes in milliseconds

// Enable persistent auth state
export const enablePersistence = async () => {
  try {
    // Wait for Firebase to initialize
    await initializationPromise;
    await setPersistence(auth, browserLocalPersistence);
  } catch (error) {
    console.error('Error setting persistence:', error);
    // Don't throw the error as this is not critical
  }
};

// Handle user logout
export const logout = async () => {
  try {
    // Wait for Firebase to initialize
    await initializationPromise;
    await signOut(auth);
    // Clear any user data or state if needed
    return true;
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

// Reset the inactivity timer
export const resetInactivityTimer = () => {
  if (inactivityTimeout) {
    clearTimeout(inactivityTimeout);
  }
  
  inactivityTimeout = setTimeout(async () => {
    try {
      await initializationPromise;
      await signOut(auth);
      console.log('User signed out due to inactivity');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, TIMEOUT_DURATION);
};

// Setup activity listeners
export const setupActivityListeners = () => {
  // List of events to track
  const events = [
    'mousedown',
    'mousemove',
    'keypress',
    'scroll',
    'touchstart'
  ];

  // Add listeners for each event
  events.forEach(event => {
    document.addEventListener(event, resetInactivityTimer);
  });

  // Initial setup of the timer
  resetInactivityTimer();
};

// Cleanup activity listeners
export const cleanupActivityListeners = () => {
  const events = [
    'mousedown',
    'mousemove',
    'keypress',
    'scroll',
    'touchstart'
  ];

  events.forEach(event => {
    document.removeEventListener(event, resetInactivityTimer);
  });

  if (inactivityTimeout) {
    clearTimeout(inactivityTimeout);
  }
};

// Initialize Firebase Auth persistence
export const initializeAuthPersistence = async () => {
  try {
    // Wait for Firebase to initialize
    await initializationPromise;
    await setPersistence(auth, browserLocalPersistence);
    console.log('Firebase Auth persistence initialized');
  } catch (error) {
    console.error('Error initializing auth persistence:', error);
    // Don't throw the error as this is not critical
  }
};

// Initialize auth persistence when the module loads
initializeAuthPersistence();

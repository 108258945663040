import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import { useTheme } from '../contexts/ThemeContext'; // Import useTheme hook
import TopBar from './TopBar';
import Sidebar from './Sidebar';

export const PrivateRoute = ({ children, excludeRoles = [] }) => {
  const { currentUser, userRole, loading, initializing } = useAuth();
  const { isCollapsed } = useSidebar();
  const { isDarkMode } = useTheme(); // Use the isDarkMode variable from the ThemeContext
  const location = useLocation();

  // Show loading spinner while authentication is initializing
  if (initializing) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: isDarkMode ? '#0F172A' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1F1F1F'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Redirect to login if not authenticated
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check role restrictions
  if (excludeRoles.includes(userRole)) {
    return <Navigate to="/dashboard" replace />;
  }

  // Render the protected content
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        minHeight: '100vh',
        bgcolor: isDarkMode ? '#0F172A' : '#FFFFFF',
        color: isDarkMode ? '#FFFFFF' : '#1F1F1F'
      }}
    >
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: {
            xs: 0,
            sm: isCollapsed ? '80px' : '280px'
          },
          transition: 'all 0.3s ease',
          minHeight: '100vh',
          width: {
            xs: '100%',
            sm: `calc(100% - ${isCollapsed ? '80px' : '280px'})`
          },
          overflow: 'hidden',
          pt: '70px',
          bgcolor: 'transparent',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: isDarkMode ? '#0F172A' : '#FFFFFF',
            zIndex: -1
          }
        }}
      >
        <TopBar />
        <Box 
          sx={{ 
            flex: 1, 
            p: 3,
            bgcolor: 'transparent'
          }}
        >
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%',
              bgcolor: 'transparent'
            }}>
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Box>
  );
};

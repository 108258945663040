import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { db } from '../firebaseConfig';
import { 
  collection, 
  query, 
  where, 
  onSnapshot,
} from 'firebase/firestore';
import { useTheme } from '../contexts/ThemeContext';
import { useSidebar } from '../contexts/SidebarContext';
import { useAuth } from '../contexts/AuthContext';
import { 
  FaSearch, 
  FaCog, 
  FaUserCircle, 
  FaSignOutAlt,
  FaSun,
  FaMoon,
  FaBars,
  FaChevronDown,
  FaUser,
} from 'react-icons/fa';
import '../styles/TopBar.css';

const TopBar = () => {
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const { currentUser, logout } = useAuth();
  const { isCollapsed, toggleSidebar, isMobileOpen, setIsMobileOpen } = useSidebar();
  const { isDarkMode, toggleTheme } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const searchInputRef = useRef(null);
  const [notificationState, setNotificationState] = useState({ system: 0, messages: 0 });

  useEffect(() => {
    if (!currentUser) {
      console.warn('No current user - redirecting to login');
      navigate('/login');
      return;
    }

    // Listen for both system notifications and unread messages
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(
      notificationsRef,
      where('userId', '==', currentUser.uid),
      where('read', '==', false)
    );

    const conversationsRef = collection(db, 'conversations');
    const conversationsQuery = query(
      conversationsRef,
      where('participants', 'array-contains', currentUser.uid)
    );

    // Listen for system notifications
    const unsubscribeNotifications = onSnapshot(notificationsQuery, (snapshot) => {
      setNotificationState(prev => ({ ...prev, system: snapshot.size }));
      updateTotalNotifications(snapshot.size, 'system');
    });

    // Listen for unread messages
    const unsubscribeConversations = onSnapshot(conversationsQuery, (snapshot) => {
      let unreadMessages = 0;
      snapshot.docs.forEach(doc => {
        const conv = doc.data();
        if (conv.lastMessage && 
            conv.lastMessageSender !== currentUser.uid && 
            conv.participants.includes(currentUser.uid) &&
            (!conv.lastRead?.[currentUser.uid] || 
             conv.lastMessageTime.seconds > conv.lastRead[currentUser.uid].seconds)) {
          unreadMessages++;
        }
      });
      setNotificationState(prev => ({ ...prev, messages: unreadMessages }));
      updateTotalNotifications(unreadMessages, 'messages');
    });

    return () => {
      unsubscribeNotifications();
      unsubscribeConversations();
    };
  }, [currentUser, navigate]);

  // Function to update total notifications
  const updateTotalNotifications = (count, type) => {
    setNotificationState(prev => {
      const newState = { ...prev, [type]: count };
      const total = newState.system + newState.messages;
      setNotificationCount(total);
      return newState;
    });
  };

  const handleLogout = async () => {
    try {
      setLogoutLoading(true);
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const handleNotificationsClick = () => {
    navigate('/notifications');
  };

  return (
    <div className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 z-50">
      <div className="h-full px-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => setIsMobileOpen(!isMobileOpen)}
            className="p-2 rounded-lg text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 md:hidden"
          >
            <FaBars className="w-5 h-5" />
          </button>

          <div className="relative hidden md:flex items-center">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaSearch className="h-5 w-5 text-gray-400" />
            </div>
            <input
              ref={searchInputRef}
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400"
            />
            {isSearching && (
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-5 h-5 border-2 border-primary-500 border-t-transparent rounded-full"
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <button
            onClick={toggleTheme}
            className="relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-gray-200 dark:bg-gray-600"
            role="switch"
            aria-checked={isDarkMode}
          >
            <span
              className={`${
                isDarkMode ? 'translate-x-5' : 'translate-x-0'
              } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out dark:bg-gray-900`}
            >
              {isDarkMode ? (
                <FaMoon className="h-[12px] w-[12px] text-gray-900 dark:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              ) : (
                <FaSun className="h-[12px] w-[12px] text-gray-900 dark:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              )}
            </span>
          </button>

          <button 
            onClick={() => navigate('/notifications')}
            className="notification-bell button"
            aria-label="Notifications"
          >
            <svg viewBox="0 0 448 512" className="bell">
              <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z" />
            </svg>
            {notificationCount > 0 && (
              <span className="notification-count">
                {notificationCount > 99 ? '99+' : notificationCount}
              </span>
            )}
          </button>
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center gap-2 px-2 py-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400">
              <div className="relative flex items-center">
                <div className="relative w-8 h-8">
                  {currentUser?.photoURL ? (
                    <img
                      src={currentUser.photoURL}
                      alt="Profile"
                      className="rounded-full object-cover w-full h-full ring-2 ring-white dark:ring-gray-800"
                    />
                  ) : (
                    <div className="w-full h-full rounded-full bg-gradient-to-br from-primary-400 to-primary-600 dark:from-primary-600 dark:to-primary-800 flex items-center justify-center text-white font-medium">
                      {currentUser?.email?.[0].toUpperCase()}
                    </div>
                  )}
                  <div className="absolute bottom-0 right-0 w-2.5 h-2.5 rounded-full bg-green-500 ring-2 ring-white dark:ring-gray-800"></div>
                </div>
                {!isCollapsed && (
                  <div className="ml-3 flex flex-col items-start">
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {currentUser?.displayName || currentUser?.email?.split('@')[0]}
                    </span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {currentUser?.email}
                    </span>
                  </div>
                )}
                <FaChevronDown className="w-4 h-4 ml-2 text-gray-500 dark:text-gray-400" />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-64 rounded-xl bg-white dark:bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-800 focus:outline-none">
                <div className="px-4 py-4">
                  <div className="flex items-center">
                    <div className="relative w-12 h-12">
                      {currentUser?.photoURL ? (
                        <img
                          src={currentUser.photoURL}
                          alt="Profile"
                          className="rounded-full object-cover w-full h-full ring-2 ring-white dark:ring-gray-800"
                        />
                      ) : (
                        <div className="w-full h-full rounded-full bg-gradient-to-br from-primary-400 to-primary-600 dark:from-primary-600 dark:to-primary-800 flex items-center justify-center text-white text-xl font-medium">
                          {currentUser?.email?.[0].toUpperCase()}
                        </div>
                      )}
                      <div className="absolute bottom-0 right-0 w-3 h-3 rounded-full bg-green-500 ring-2 ring-white dark:ring-gray-800"></div>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {currentUser?.displayName || currentUser?.email?.split('@')[0]}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {currentUser?.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => navigate('/profile')}
                        className={`${
                          active ? 'bg-gray-50 dark:bg-gray-800' : ''
                        } group flex w-full items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100`}
                      >
                        <FaUserCircle className="w-5 h-5 mr-3 text-gray-400 group-hover:text-primary-500" />
                        Profile
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => navigate('/settings')}
                        className={`${
                          active ? 'bg-gray-50 dark:bg-gray-800' : ''
                        } group flex w-full items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100`}
                      >
                        <FaCog className="w-5 h-5 mr-3 text-gray-400 group-hover:text-primary-500" />
                        Settings
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        disabled={logoutLoading}
                        className={`${
                          active ? 'bg-red-50 dark:bg-red-900/20' : ''
                        } group flex w-full items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300`}
                      >
                        {logoutLoading ? (
                          <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                            className="w-5 h-5 border-2 border-red-500 border-t-transparent rounded-full"
                          />
                        ) : (
                          <FaSignOutAlt className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500" />
                        )}
                        {logoutLoading ? 'Logging out...' : 'Sign out'}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUserRole } from '../firebase/userService';
import { logout } from '../firebase/authService';
import { useAuth } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import { useTheme } from '../contexts/ThemeContext';
import { 
  FaHome, 
  FaBuilding, 
  FaUsers, 
  FaTools, 
  FaPoundSign,
  FaChartLine,
  FaUserTie,
  FaSignOutAlt,
  FaBars,
  FaChevronLeft,
  FaChevronDown,
  FaComments
} from 'react-icons/fa';

const Sidebar = () => {
  const { isCollapsed, toggleSidebar, isMobileOpen, setIsMobileOpen } = useSidebar();
  const { isDarkMode, primaryColor } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const role = await getCurrentUserRole(currentUser.uid);
        setUserRole(role);
      }
    };
    fetchUserRole();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleSubmenuClick = (title) => {
    setActiveSubmenu(activeSubmenu === title ? null : title);
  };

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const menuItems = [
    { 
      title: 'Dashboard', 
      path: '/', 
      icon: FaHome
    },
    { 
      title: 'Properties',
      path: '/properties',
      icon: FaBuilding,
      submenu: userRole === 'landlord' 
        ? [{ title: 'All Properties', path: '/properties' }]
        : [
            { title: 'All Properties', path: '/properties' },
            { title: 'Add Property', path: '/properties/add' }
          ]
    },
    ...(userRole !== 'landlord' ? [{ 
      title: 'Tenants',
      path: '/tenants',
      icon: FaUsers,
      submenu: [
        { title: 'All Tenants', path: '/tenants' },
        { title: 'Add Tenant', path: '/tenants/add' },
      ]
    }] : []),
    { 
      title: 'Messages',
      path: '/messages',
      icon: FaComments
    },
    { 
      title: 'Maintenance',
      path: '/maintenance',
      icon: FaTools
    },
    { 
      title: 'Finance',
      path: '/finance',
      icon: FaPoundSign
    },
    { 
      title: 'Reports',
      path: '/reports',
      icon: FaChartLine
    },
    ...(userRole && ['admin', 'agent', 'administrator'].includes(userRole) ? [{
      title: 'Landlords',
      path: '/landlords',
      icon: FaUserTie,
      submenu: [
        { title: 'All Landlords', path: '/landlords' },
        { title: 'Add Landlord', path: '/add-landlord' },
      ]
    }] : [])
  ];

  return (
    <>
      {/* Mobile overlay */}
      {isMobileOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-40 lg:hidden"
          onClick={() => setIsMobileOpen(false)}
        />
      )}
      
      {/* Sidebar */}
      <aside
        className={`
          fixed top-0 left-0 z-40 h-screen 
          ${isDarkMode 
            ? 'bg-gray-900/95 text-gray-100 border-gray-800' 
            : 'bg-white/95 text-gray-900 border-gray-200'} 
          backdrop-blur-md border-r
          transition-all duration-300 ease-in-out
          ${isCollapsed ? 'w-20' : 'w-64'}
          ${isMobileOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        `}
      >
        {/* Logo section */}
        <div className={`flex items-center justify-between h-16 px-4 border-b ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}>
          <Link to="/" className="flex items-center gap-2">
            {!isCollapsed && (
              <span className="text-xl font-semibold bg-gradient-to-r from-${primaryColor} to-${primaryColor} bg-clip-text text-transparent">
                Plutoo
              </span>
            )}
          </Link>
          <button
            onClick={toggleSidebar}
            className={`p-2 rounded-md transition-colors lg:block hidden
              ${isDarkMode 
                ? 'text-gray-400 hover:bg-gray-800 hover:text-white' 
                : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
          >
            {isCollapsed ? <FaChevronLeft size={16} /> : <FaBars size={16} />}
          </button>
        </div>

        {/* Navigation */}
        <nav className="h-[calc(100vh-4rem)] overflow-y-auto py-4">
          <ul className="space-y-1 px-2">
            {menuItems.map((item) => (
              <li key={item.title}>
                {item.submenu ? (
                  <div className="space-y-1">
                    <button
                      onClick={() => handleSubmenuClick(item.title)}
                      className={`
                        w-full flex items-center justify-between px-3 py-2 rounded-lg
                        text-sm font-medium transition-colors
                        ${isActive(item.path) 
                          ? `${isDarkMode 
                              ? 'bg-gray-800 text-white' 
                              : 'bg-gray-100 text-gray-900'}` 
                          : `${isDarkMode
                              ? 'text-gray-400 hover:bg-gray-800 hover:text-white'
                              : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
                      `}
                    >
                      <span className="flex items-center gap-3">
                        <item.icon size={20} />
                        {!isCollapsed && item.title}
                      </span>
                      {!isCollapsed && (
                        <FaChevronDown
                          size={12}
                          className={`transform transition-transform ${
                            activeSubmenu === item.title ? 'rotate-180' : ''
                          }`}
                        />
                      )}
                    </button>
                    {activeSubmenu === item.title && !isCollapsed && (
                      <ul className="pl-10 space-y-1">
                        {item.submenu.map((subItem) => (
                          <li key={subItem.title}>
                            <Link
                              to={subItem.path}
                              className={`
                                block px-3 py-2 rounded-lg text-sm transition-colors
                                ${isActive(subItem.path)
                                  ? `${isDarkMode 
                                      ? 'bg-gray-800 text-white' 
                                      : 'bg-gray-100 text-gray-900'}` 
                                  : `${isDarkMode
                                      ? 'text-gray-400 hover:bg-gray-800 hover:text-white'
                                      : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
                              `}
                            >
                              {subItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    className={`
                      flex items-center gap-3 px-3 py-2 rounded-lg
                      text-sm font-medium transition-colors
                      ${isActive(item.path)
                        ? `${isDarkMode 
                            ? 'bg-gray-800 text-white' 
                            : 'bg-gray-100 text-gray-900'}` 
                        : `${isDarkMode
                            ? 'text-gray-400 hover:bg-gray-800 hover:text-white'
                            : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
                    `}
                  >
                    <item.icon size={20} />
                    {!isCollapsed && item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          {/* Logout button */}
          <div className="px-2 mt-auto pt-4 border-t border-${isDarkMode ? 'gray-800' : 'gray-200'}">
            <button
              onClick={handleLogout}
              className="w-full flex items-center gap-3 px-3 py-2 rounded-lg
                text-sm font-medium text-${isDarkMode ? 'gray-400' : 'gray-600'} hover:bg-${isDarkMode ? 'gray-800' : 'gray-200'} hover:text-${isDarkMode ? 'white' : 'gray-900'}
                transition-colors"
            >
              <FaSignOutAlt size={20} />
              {!isCollapsed && 'Logout'}
            </button>
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;

import { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getFirebaseDb } from '../config/firebase';
import { useAuth } from './AuthContext';

const defaultColors = {
  primary: '#14FFA3',
  secondary: '#1F1F1F'
};

const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
  primaryColor: defaultColors.primary,
  secondaryColor: defaultColors.secondary,
  setPrimaryColor: () => {},
  setSecondaryColor: () => {},
  isAdmin: false,
});

export const ThemeProvider = ({ children }) => {
  const { currentUser, userRole } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(defaultColors.primary);
  const [secondaryColor, setSecondaryColor] = useState(defaultColors.secondary);

  // Check if user is admin
  useEffect(() => {
    setIsAdmin(userRole === 'admin' || userRole === 'administrator');
  }, [userRole]);

  // Load user preferences
  useEffect(() => {
    const loadUserPreferences = async () => {
      if (!currentUser) return;
      
      try {
        const db = getFirebaseDb();
        const userPrefsRef = doc(db, 'userPreferences', currentUser.uid);
        const userPrefsDoc = await getDoc(userPrefsRef);
        
        if (userPrefsDoc.exists()) {
          const prefs = userPrefsDoc.data();
          setIsDarkMode(prefs?.theme?.isDarkMode ?? false);
          setPrimaryColor(prefs?.theme?.primaryColor ?? defaultColors.primary);
          setSecondaryColor(prefs?.theme?.secondaryColor ?? defaultColors.secondary);
        } else {
          // Create default preferences
          await setDoc(userPrefsRef, {
            theme: {
              isDarkMode: false,
              primaryColor: defaultColors.primary,
              secondaryColor: defaultColors.secondary,
            }
          });
        }
      } catch (error) {
        console.error('Error loading user preferences:', error);
      }
    };

    loadUserPreferences();
  }, [currentUser]);

  // Save user preferences
  const saveUserPreferences = async () => {
    if (!currentUser) return;

    try {
      const db = getFirebaseDb();
      const userPrefsRef = doc(db, 'userPreferences', currentUser.uid);
      await updateDoc(userPrefsRef, {
        theme: {
          isDarkMode,
          primaryColor,
          secondaryColor,
        }
      });
    } catch (error) {
      console.error('Error saving user preferences:', error);
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  // Save preferences when theme changes
  useEffect(() => {
    if (currentUser) {
      saveUserPreferences();
    }
  }, [isDarkMode, primaryColor, secondaryColor, currentUser]);

  // Apply theme to root element and update CSS variables
  useEffect(() => {
    const root = window.document.documentElement;
    const body = window.document.body;
    
    // Toggle dark mode class
    if (isDarkMode) {
      root.classList.add('dark');
      body.style.backgroundColor = '#0F172A';
      body.style.color = '#FFFFFF';
    } else {
      root.classList.remove('dark');
      body.style.backgroundColor = '#FFFFFF';
      body.style.color = '#1F1F1F';
    }

    // Update CSS variables
    root.style.setProperty('--color-primary', primaryColor);
    root.style.setProperty('--color-secondary', secondaryColor);
  }, [isDarkMode, primaryColor, secondaryColor]);

  // Helper function to adjust color brightness
  const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme,
        primaryColor,
        secondaryColor,
        setPrimaryColor,
        setSecondaryColor,
        isAdmin,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

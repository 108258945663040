import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { PrivateRoute } from './components/PrivateRoute';

// Lazy load components with error boundaries
const LoginPage = React.lazy(() => import('./LoginPage'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Properties = React.lazy(() => import('./components/Properties'));
const PropertyDetails = React.lazy(() => import('./components/PropertyDetails'));
const AddProperty = React.lazy(() => import('./components/AddProperty'));
const EditProperty = React.lazy(() => import('./components/EditProperty'));
const Tenants = React.lazy(() => import('./components/Tenants'));
const AddTenant = React.lazy(() => import('./components/AddTenant'));
const TenantDetails = React.lazy(() => import('./components/TenantDetails'));
const EditTenant = React.lazy(() => import('./components/EditTenant'));
const Maintenance = React.lazy(() => import('./components/Maintenance'));
const MaintenanceDetails = React.lazy(() => import('./components/MaintenanceDetails'));
const Finance = React.lazy(() => import('./components/Finance'));
const Reports = React.lazy(() => import('./components/Reports'));
const Settings = React.lazy(() => import('./components/Settings'));
const Profile = React.lazy(() => import('./components/Profile'));
const Landlords = React.lazy(() => import('./components/Landlords'));
const LandlordDetails = React.lazy(() => import('./components/LandlordDetails'));
const AddLandlord = React.lazy(() => import('./components/AddLandlord'));
const Notifications = React.lazy(() => import('./components/Notifications'));
const Payments = React.lazy(() => import('./components/Payments'));
const CertificateManagement = React.lazy(() => import('./components/CertificateManagement'));
const Messages = React.lazy(() => import('./components/Messages'));

// Loading component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen bg-secondary">
    <div className="flex flex-col items-center">
      <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
      <span className="mt-4 text-text-secondary">Loading...</span>
    </div>
  </div>
);

const App = () => {
  return (
    <ThemeProvider>
      <SidebarProvider>
        <NotificationProvider>
          <Suspense fallback={<LoadingFallback />}>
            <div className="min-h-screen w-full bg-background text-text-primary">
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/properties"
                  element={
                    <PrivateRoute>
                      <Properties />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/properties/add"
                  element={
                    <PrivateRoute>
                      <AddProperty />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/properties/:id"
                  element={
                    <PrivateRoute>
                      <PropertyDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-property/:id"
                  element={
                    <PrivateRoute>
                      <EditProperty />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/tenants"
                  element={
                    <PrivateRoute>
                      <Tenants />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/tenants/add"
                  element={
                    <PrivateRoute>
                      <AddTenant />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/tenants/:id"
                  element={
                    <PrivateRoute>
                      <TenantDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-tenant/:id"
                  element={
                    <PrivateRoute>
                      <EditTenant />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/maintenance"
                  element={
                    <PrivateRoute>
                      <Maintenance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/maintenance/:id"
                  element={
                    <PrivateRoute>
                      <MaintenanceDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/finance"
                  element={
                    <PrivateRoute>
                      <Finance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <PrivateRoute>
                      <Reports />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/landlords"
                  element={
                    <PrivateRoute>
                      <Landlords />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/landlords/:id"
                  element={
                    <PrivateRoute>
                      <LandlordDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/add-landlord"
                  element={
                    <PrivateRoute>
                      <AddLandlord />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <PrivateRoute>
                      <Notifications />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/payments"
                  element={
                    <PrivateRoute>
                      <Payments />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/certificates"
                  element={
                    <PrivateRoute>
                      <CertificateManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/messages"
                  element={
                    <PrivateRoute>
                      <Messages />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </div>
          </Suspense>
        </NotificationProvider>
      </SidebarProvider>
    </ThemeProvider>
  );
};

export default App;

import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  browserLocalPersistence, 
  browserSessionPersistence, 
  setPersistence 
} from 'firebase/auth';
import { 
  getFirestore,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  persistentLocalCache,
  persistentSingleTabManager
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';

class FirebaseConfig {
  constructor() {
    this.initialized = false;
    this.app = null;
    this.auth = null;
    this.db = null;
    this.rtdb = null;
    this.storage = null;
    this.analytics = null;
  }

  getConfig() {
    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Validate config
    if (!config.apiKey || !config.authDomain || !config.projectId) {
      throw new Error('Missing required Firebase configuration. Check your environment variables.');
    }

    return config;
  }

  validateConfig(config) {
    const requiredFields = ['apiKey', 'authDomain', 'projectId'];
    const missingFields = requiredFields.filter(field => !config[field]);
    
    if (missingFields.length > 0) {
      throw new Error(`Missing required Firebase configuration fields: ${missingFields.join(', ')}`);
    }
  }

  async initialize() {
    if (this.initialized) {
      return;
    }

    try {
      const config = this.getConfig();
      this.validateConfig(config);

      // Initialize Firebase app
      this.app = initializeApp(config);

      // Initialize Authentication
      this.auth = getAuth(this.app);
      await setPersistence(this.auth, browserLocalPersistence);

      // Initialize Firestore with persistent cache
      this.db = initializeFirestore(this.app, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        cache: persistentLocalCache({
          tabManager: persistentSingleTabManager()
        })
      });

      // Initialize Realtime Database
      if (config.databaseURL) {
        this.rtdb = getDatabase(this.app);
      }

      // Initialize Storage
      this.storage = getStorage(this.app);

      // Initialize Analytics if supported
      if (await isSupported()) {
        this.analytics = getAnalytics(this.app);
      }

      this.initialized = true;
    } catch (error) {
      console.error('Error initializing Firebase:', error);
      throw error;
    }
  }

  getApp() {
    return this.app;
  }

  getAuth() {
    return this.auth;
  }

  getDb() {
    return this.db;
  }

  getRtdb() {
    return this.rtdb;
  }

  getStorage() {
    return this.storage;
  }

  getAnalytics() {
    return this.analytics;
  }
}

// Create and initialize a single instance
const firebaseInstance = new FirebaseConfig();

// Export functions that use the singleton instance
export const initFirebase = () => firebaseInstance.initialize();
export const getFirebaseApp = () => firebaseInstance.getApp();
export const getFirebaseAuth = () => firebaseInstance.getAuth();
export const getFirebaseDb = () => firebaseInstance.getDb();
export const getFirebaseRtdb = () => firebaseInstance.getRtdb();
export const getFirebaseStorage = () => firebaseInstance.getStorage();
export const getFirebaseAnalytics = () => firebaseInstance.getAnalytics();

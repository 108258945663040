import { 
  initFirebase,
  getFirebaseAuth,
  getFirebaseDb,
  getFirebaseRtdb,
  getFirebaseStorage,
  getFirebaseAnalytics
} from './config/firebase';

// Initialize Firebase and export services
let auth = null;
let db = null;
let rtdb = null;
let storage = null;
let analytics = null;
let isInitialized = false;

const initializeServices = async () => {
  if (!isInitialized) {
    try {
      await initFirebase();
      auth = getFirebaseAuth();
      db = getFirebaseDb();
      rtdb = getFirebaseRtdb();
      storage = getFirebaseStorage();
      analytics = getFirebaseAnalytics();
      isInitialized = true;
      console.log('Firebase services initialized successfully');
    } catch (error) {
      console.error('Error initializing Firebase services:', error);
      throw error;
    }
  }
  return { auth, db, rtdb, storage, analytics };
};

// Initialize services immediately
const initializationPromise = initializeServices();

// Export the services and initialization promise
export { auth, db, rtdb, storage, analytics, initializeServices, initializationPromise };
